import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { financial_data } from "../../data/financial_data";
import Link from "next/link";
import FaqAccordion from "../dao/Faq";
import VideoBlock from "../dao/VideoBlock";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Financial_carousel = () => {
  return (
    <>
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 1,
          },
          995: {
            slidesPerView: 1,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {financial_data.map((item) => {
          const { id, title, desc, date } = item;
          return (
            <SwiperSlide className=" overflow-visible " key={id}>
              <section className="bg-light-base dark:bg-jacarta-900">
                <div className="container">
                  <div className="lg:flex lg:flex-nowrap">
                    <div className="lg:w-[59%]">
                      <VideoBlock />
                    </div>
                    {/* End VideoBlock */}

                    <div className="py-10 lg:w-[45%] lg:pl-16">
                      <h2 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                        Get Experience. Were A Results Driven Team.
                      </h2>
                      <p className="dark:text-jacarta-300 mb-8 text-lg leading-normal">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam
                      </p>
                      <p className="dark:text-jacarta-300 mb-10">
                        Every digital creation available through MakersPlace is
                        an authentic and truly unique digital creation, signed
                        and issued by the creator — made possible by blockchain
                        technology. Even if the digital creation is copied, it
                        wont be the authentic and originally signed version.
                      </p>
                      <div className="flex space-x-4 sm:space-x-10">
                        <div className="flex space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="fill-accent h-8 w-8 shrink-0"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                          </svg>
                          <div>
                            <span className="font-display text-jacarta-700 block text-xl dark:text-white">
                              11,2k+
                            </span>
                            <span className="dark:text-jacarta-300 text-jacarta-700">
                              Products launched
                            </span>
                          </div>
                        </div>
                        <div className="flex space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="fill-accent h-8 w-8 shrink-0"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                          </svg>
                          <div>
                            <span className="font-display text-jacarta-700 block text-xl dark:text-white">
                              99,7%
                            </span>
                            <span className="dark:text-jacarta-300 text-jacarta-700">
                              Satisfaction Rate
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* 
              <article className="rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
                <div className="mb-4 flex flex-wrap gap-4 text-2xs dark:text-jacarta-300">
                  <div className="flex flex-wrap items-center space-x-2">
                    <span className="text-accent">
                      <Link href="/single_post/post_1">
                        <a className="uppercase">Finance</a>
                      </Link>
                    </span>
                  </div>
                  <span>
                    <time>{date}</time>
                  </span>
                </div>

                <h2 className="mb-5 font-display text-xl text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent">
                  <Link href="/single_post/post_1">
                    <a>{title}</a>
                  </Link>
                </h2>
                <p className="mb-8 text text-jacarta-700 dark:text-jacarta-300">
                  {desc}
                </p>
                <div className="overflow-hidden">
                  <Link href="/single_post/post_1">
                    <a className="inline-block transition-transform will-change-transform hover:translate-x-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-accent"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                      </svg>
                    </a>
                  </Link>
                </div>
              </article> */}
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default Financial_carousel;
