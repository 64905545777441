export const financial_data = [
	{
		title: 'Expanding the Global NFT Ecosystem: A Preview of Chain Expansion',
		desc: 'The goal of this new editor is to make adding rich content to WordPress simple…',
		id: 1,
		date: '5 Fab, 2022',
	},
	{
		title: 'OpenSea Acquires Gem to Invest in Pro Experience',
		desc: 'The goal of this new editor is to make adding rich content to WordPress simple…',
		id: 2,
		date: '5 Fab, 2022',
	},
	{
		title: '10 Marketing Trends That You Should Be Prepared for in 2022',
		desc: 'The goal of this new editor is to make adding rich content to WordPress simple…',
		id: 3,
		date: '5 Fab, 2022',
	},
	{
		title: '10 Marketing Trends That You Should Be Prepared for in 2022',
		desc: 'The goal of this new editor is to make adding rich content to WordPress simple…',
		id: 4,
		date: '5 Fab, 2022',
	},
];
