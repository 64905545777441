export const services_data = [
  //   {
  //     title: "NFT Consultant Analytics",
  //     desc: "Once you've set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.",
  //     id: "0NFT Consultant Analytics",
  //     icon: "service-ico-1",
  //   },
  {
    title: "Web Development",
    desc: " I can create stunning and efficient web applications using React, Tailwind, TypeScript, Next.js, and more. Whether you need a landing page, a blog, an e-commerce site, or anything else, I can make it happen.",
    id: "1NFT Marketing",
    icon: "service-ico-2",
  },
  {
    title: "Application Development",
    desc: "I can build cross-platform mobile applications using Flutter. Whether you need an app for iOS, Android, or both, I can deliver it. I can also integrate various APIs and features to make your app more powerful and useful.",
    id: "2NFT Strategy",
    icon: "service-ico-3",
  },
  {
    title: "UI/UX Design",
    desc: " I can design beautiful and user-friendly interfaces for your web or mobile applications using Figma. I can also create prototypes and mockups to test and improve your designs. I pay attention to every detail and ensure your designs are responsive, accessible, and engaging.",
    id: "3NFT Technology",
    icon: "service-ico-4",
  },
  //   {
  //     title: "Advisers & Intermediaries",
  //     desc: "Once you've set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.",
  //     id: "4Advisers & Intermediaries",
  //     icon: "service-ico-5",
  //   },
  //   {
  //     title: "ICO Token",
  //     desc: "Once you've set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.",
  //     id: "5ICO Token",
  //     icon: "service-ico-6",
  //   },
];
